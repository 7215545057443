import React from "react";

import "./styles/app.scss";

function App() {
	return (
		<div className="app">
			<h3>Coming soon!</h3>
		</div>
	);
}

export default App;
